import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { CardTurn } from '../../components/library'

const profile = {
    role: 'Child',
    name: 'James',
    avatar: 'panda',
    id: 'b7dd4698-d0a5-11eb-b8bc-0242ac130003'
}

function cardTurn() {
    return (
        <LibraryWrapper>
            <CardTurn profile={profile} description="1. How is your asthma today?" />
            <hr />
            <CardTurn profile={profile} shade="dark" description="7. During the last 4 weeks, how many days did your child have any daytime asthma symptoms?" />
        </LibraryWrapper>
    )
}

export default cardTurn
